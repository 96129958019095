import React, { useState } from "react"
import { navigate } from "@reach/router"
import useAuth from "../../util/useStrapiAuth"
import { passRedirectThroughAuth } from "../../util/authRedirect"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

const Login = ({ location }) => {
  const { login } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [submitError, setSubmitError] = useState(null)

  const processLogin = (data, e) => {
    e.preventDefault()
    setServerState({ submitting: true })
    login({ identifier: data.username, password: data.password })
      .then(() => {
        setServerState({ submitting: false })
        navigate(location.state.redirect, { state: { redirect: null } })
      })
      .catch(e => {
        setServerState({ submitting: false })
        const msg = e.response?.data?.message?.[0].messages?.[0].message
        setSubmitError(
          msg ??
            "An error has occurred. Please check your internet connection and try again."
        )
      })
  }

  return (
    <div className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="contact-form">
              <div className="form">
                <div className="form-group col-md-6">
                  <h3 style={{ textAlign: "center" }}>
                    Please use your credentials to login:
                  </h3>
                  <form onSubmit={handleSubmit(processLogin)}>
                    <div className="form-group">
                      <input
                        {...register("username", {
                          required: "This field is required.",
                        })}
                        className="form-control"
                        type="text"
                        placeholder="Username/email"
                      />
                      <span className="formFieldError">
                        {errors?.username && errors.username.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        {...register("password", {
                          required: "This field is required.",
                        })}
                        className="form-control"
                        type="password"
                        placeholder="password"
                      />
                      <span className="formFieldError">
                        {errors?.password && errors.password.message}
                      </span>
                    </div>
                    <div className="btndiv">
                      <button
                        type="submit"
                        disabled={serverState.submitting}
                        className="btn"
                      >
                        {serverState.submitting ? "signing in.." : "Sign In"}
                      </button>
                    </div>
                    {submitError?.length > 1 && (
                      <p className="formSubmitError">{submitError}</p>
                    )}
                  </form>
                  <div style={{ textAlign: "center" }}>
                    Don't have an account yet?{" "}
                    <Link
                      key="1"
                      to="/register"
                      state={{
                        redirect: passRedirectThroughAuth(
                          location,
                          "/register"
                        ),
                      }}
                    >
                      Register here
                    </Link>{" "}
                    for free.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
