import React from "react"
import Login from "../components/auth/login"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Layout from "../components/layout"

const LoginPage = ({ location }) => {
  return (
    <Layout currPage="login" location={location}>
      <PageHeader pageTitle="Login" />
      <PageBody>
        <Login location={location} />
      </PageBody>
    </Layout>
  )
}

export default LoginPage
